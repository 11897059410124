const getTableDatafromCustomerData = (customerData) => {
  const tableData = [];
  customerData.forEach((customer) => {
    const { Household: houseHold } = customer;
    const customerId = Object.keys(houseHold)[0];
    const { customerDetails } = houseHold[customerId];
    tableData.push(customerDetails);
  });

  return tableData;
};

export default getTableDatafromCustomerData;
