import React, { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { DashboardError } from './DashboardError';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { DashboardIcon } from '../Header/Icons';
import { Title } from '../Header';
import { LocationSelector } from './LocationSelector';
import addFilter from '../Diem/addFilter';
import { LastRefreshDate } from '../Header/LastRefreshDate';
import { Route, useHistory } from 'react-router-dom';
import { dashboardRedirectionMapping } from '../../Enums/dashboardWidgetMapping';
import { Trends } from '../Analytics/Trends';
import { UserContext } from '../UserContext';
import { useContext } from 'react';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '180px',
  vizWidth: '100%',
  vizHeight: '180px',
};

const LOCATION_ID_KEY = 'paramLocationID';
const OFFICE_ID_KEY = 'paramOfficeID';
const NAME_KEY = 'paramName';

const PORTFOLIO_PATH = '/home/portfolio';
const ANALYTICS_PATH = '/home/analytics';
const SCOPED_ANALYTICS_PATH = '/home/team-analytics';
const PERSONAL_ANALYTICS_PATH = '/home/my-analytics';
const ANALYTICS_CUSTOMER = '/customer/all';
const ANALYTICS_TEAM = '/team';
const ANALYTICS_TRENDS = '/trends';
const ANALYTICS_CUSTOMER_PATH = `${ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const ANALYTICS_TEAM_PATH = `${ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const ANALYTICS_TRENDS_PATH = `${ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const SCOPED_ANALYTICS_CUSTOMER_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const SCOPED_ANALYTICS_TEAM_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const SCOPED_ANALYTICS_TRENDS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const PERSONAL_ANALYTICS_CUSTOMER_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const PERSONAL_ANALYTICS_TEAM_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const PERSONAL_ANALYTICS_TRENDS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_TRENDS}`;

export function DashboardInfobar(props) {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [location, setLocation] = useState({ locationID: -1 });
  const { locationID, officeID, name } = location;
  const infobarApiPath = `/api/metrics/v1/infobar`;
  const params = `${locationID}`;
  const infobarDetails = useViewDetails(infobarApiPath, params);
  const { fetching, success, error, data } = infobarDetails;
  const left = <LocationSelector value={locationID} setValue={setLocation} />;
  const right = <LastRefreshDate />;
  let dataWithAdditionalFilter = success ? { ...data } : {};
  if (success && typeof locationID === 'number' && locationID >= 0) {
    dataWithAdditionalFilter = { ...data };
    dataWithAdditionalFilter = addFilter(
      dataWithAdditionalFilter,
      LOCATION_ID_KEY,
      locationID
    );
    dataWithAdditionalFilter = addFilter(
      dataWithAdditionalFilter,
      OFFICE_ID_KEY,
      officeID
    );
    dataWithAdditionalFilter = addFilter(
      dataWithAdditionalFilter,
      NAME_KEY,
      name
    );
  }

  const hasPortfolio = user.isPortfolioEnabled();
  const hasAnalytics = user.isAnalyticsEnabled();
  const hasScopedAnalytics = user.isScopedAnalyticsEnabled();
  const hasPersonalAnalytics = user.isPersonalAnalyticsEnabled();
  const hasCustomerAnalytics = user.isCustomerAnalyticsEnabled();
  const hasTeamAnalytics = user.isTeamAnalyticsEnabled();
  const hasTrendsAnalytics = user.isTrendsAnalyticsEnabled();

  let portfolioLink = hasPortfolio ? PORTFOLIO_PATH : '';
  let customerAnalyticsLink;
  let teamAnalyticsLink;
  let trendsAnalyticsLink;
  if (hasPersonalAnalytics) {
    customerAnalyticsLink = hasCustomerAnalytics
      ? PERSONAL_ANALYTICS_CUSTOMER_PATH
      : '';
    teamAnalyticsLink = hasTeamAnalytics ? PERSONAL_ANALYTICS_TEAM_PATH : '';
    trendsAnalyticsLink = hasTrendsAnalytics
      ? PERSONAL_ANALYTICS_TRENDS_PATH
      : '';
  } else if (hasScopedAnalytics) {
    customerAnalyticsLink = hasCustomerAnalytics
      ? SCOPED_ANALYTICS_CUSTOMER_PATH
      : '';
    teamAnalyticsLink = hasTeamAnalytics ? SCOPED_ANALYTICS_TEAM_PATH : '';
    trendsAnalyticsLink = hasTrendsAnalytics
      ? SCOPED_ANALYTICS_TRENDS_PATH
      : '';
  } else if (hasAnalytics) {
    customerAnalyticsLink = hasCustomerAnalytics ? ANALYTICS_CUSTOMER_PATH : '';
    teamAnalyticsLink = hasTeamAnalytics ? ANALYTICS_TEAM_PATH : '';
    trendsAnalyticsLink = hasTrendsAnalytics ? ANALYTICS_TRENDS_PATH : '';
  }

  const mappingObject = {
    customerAnalyticsLink: customerAnalyticsLink,
    teamAnalyticsLink: teamAnalyticsLink,
    trendsAnalyticsLink: trendsAnalyticsLink,
    portfolioLink: portfolioLink,
  };

  const onSelect = async (marks, worksheetName, viz) => {
    const redirectTo = dashboardRedirectionMapping[worksheetName];
    
    if (redirectTo in mappingObject) {
      history.push(mappingObject[redirectTo]);
    }
  };

  return (
    <>
      <Title
        icon={DashboardIcon}
        titleText={'Dashboard'}
        left={left}
        right={right}
      />
      <Route path={`${trendsAnalyticsLink}`}>
        <Trends subType="trends" />
      </Route>
      {fetching && (
        <Skeleton type="rect" height={dimensions.placeholderHeight} />
      )}
      {success && (
        <TableauMarkSelectionView
          {...dataWithAdditionalFilter}
          {...dimensions}
          adjustHeight={false}
          onSelect={onSelect}
        />
      )}
      {error && <DashboardError />}
    </>
  );
}
