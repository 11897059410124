import axios from 'axios';

const path = '/api/user/donnaTenantDetails';

const isAgencyZoomEnabled = async () => {
  let response = {};
  let agencyZoomEnabled = false;
  try {
    const { status, data } = await axios.get(path);
    const { error, data: tenantDetails } = data;
    if (status !== 200 || error) {
      throw new Error('Error getting donna tenant details');
    }
    const { agencyZoomEnabled: agencyZoomEnabledValue } = tenantDetails;

    if (typeof agencyZoomEnabledValue !== 'boolean') {
      agencyZoomEnabled = agencyZoomEnabledValue === 'true' ? true : false;
    } else {
      agencyZoomEnabled = agencyZoomEnabledValue;
    }
    response = {
      error: false,
      data: {
        agencyZoomEnabled,
      },
    };
  } catch (error) {
    response = {
      error: true,
      errorMessage: error.message,
    };
  }
  return response;
};

export default isAgencyZoomEnabled;
