import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { OneViewDialog } from './OneViewDialog';
import { OneViewSkeleton } from './OneViewSkeleton';
import { OneViewError } from './OneViewError';
import { useCustomerData } from './useCustomerData';
import COV from '@aureus/cov-aureus';
import useActivities from './../Activities/useActivities';
import sendAction from '../Action/sendAction';
import { Predictions as PredictionCustomComponent } from '@aureus/donna-cov-views';
import managerConfig from './ManagerConfig.json';
import { OneViewQuota } from './OneViewQuota';
import getQuote from '../Quote/getQuote';
import sendQuote from '../Quote/sendQuote';
import isQuoteServiceAllowed from '../Quote/isQuoteServiceAllowed';
import isQuoteEnabled from '../Quote/isQuoteEnabled';
import getUserEmail from '../Quote/getUserEmail';
import { quoteTypes } from '../Quote/quoteTypes';
import showQuote from '../Quote/showQuote';
import { UserContext } from '../UserContext';
import getLeadHistory from '../AgencyZoom/getLeadHistory';
import createLead from '../AgencyZoom/createLead';
import agencyZoomLogin from '../AgencyZoom/login';
import getLeadCreationDetails from '../AgencyZoom/getLeadCreationDetails';
import isAgencyZoomEnabled from '../AgencyZoom/isAgencyZoomEnabled';
import isAgencyZoomServiceAllowed from '../AgencyZoom/isAgencyZoomAllowed';
import isInsuredMineEnabled from '../InsuredMine/isInsuredMineEnabled';
import createInsuredLead from '../InsuredMine/createInsuredLead';

const useStyles = makeStyles((theme) => ({
  covLeftPadding: {
    '& .oneview_main_div': {
      paddingLeft: theme.spacing(10),
      backgroundImage: 'none',
      backgroundColor: '#2d6089',
    },
    '& .oneview_nav_tab_div': {
      backgroundColor: '#2d6089 !important',
    },
    '& .oneview_nav_active_tab_div': {
      backgroundColor: '#fff !important',
      color: '#000 !important',
    },
  },
}));

export function OneView(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const userID = user.getTenantLevelUserId();
  const tenant = user.getTenant();
  const { customerId, selectedView } = useParams();
  const { backPath } = props;
  const helpVideoPlaylist = process.env.REACT_APP_LEAD_HELP_VIDEOS_LINK;
  const customerData = useCustomerData({
    customerId,
  });
  const { fetching, success, error, data } = customerData;
  const history = useHistory();
  const goBack = () => {
    if (backPath) {
      history.push(backPath);
    } else {
      history.goBack();
    }
  };

  return (
    <div>
      <OneViewDialog customerId={customerId} open={true} handleClose={goBack}>
        {fetching && <OneViewSkeleton />}
        {success && (
          <>
            <div className={classes.covLeftPadding}>
              <COV
                manageConfig={managerConfig}
                custCOV={{ aureus: { data } }}
                gotoCovFunction={() => {}}
                makeBreadcrumbArray={false}
                selected={selectedView}
                components={{
                  Recommendations: (props) => (
                    <PredictionCustomComponent
                      tenant={tenant}
                      userID={userID}
                      addLead={createLead}
                      getLeadCreationDetails={getLeadCreationDetails}
                      getLeadHistory={getLeadHistory}
                      leadLogin={agencyZoomLogin}
                      isAgencyZoomServiceAllowed={isAgencyZoomServiceAllowed}
                      isAgencyZoomEnabled={isAgencyZoomEnabled}
                      useActivities={useActivities}
                      sendAction={sendAction}
                      customerId={customerId}
                      getQuote={getQuote}
                      sendQuote={sendQuote}
                      isQuoteServiceAllowed={isQuoteServiceAllowed}
                      isQuoteEnabled={isQuoteEnabled}
                      getUserEmail={getUserEmail}
                      quoteType={quoteTypes.ACTIVITY}
                      showQuote={showQuote}
                      helpPlaylist={helpVideoPlaylist}
                      isInsuredMineEnabled={isInsuredMineEnabled}
                      addInsuredLead={createInsuredLead}
                      {...props}
                    />
                  ),
                }}
                noRouter={false}
                pathname="/"
              />
            </div>
          </>
        )}
        {error && <OneViewError customerId={customerId} />}
      </OneViewDialog>
      {success && <OneViewQuota customerId={customerId} />}
    </div>
  );
}

OneView.propTypes = {
  backPath: PropTypes.string,
};
