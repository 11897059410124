import axios from 'axios';
import qs from 'qs';
import { isQuoteAllowed } from './../../utils/donnaPlansUtil';

const path = '/api/user/donnaTenantDetails';

const isQuoteServiceAllowed = async () => {
  let response = {};
  // tenant is set to dev_hs_1 just to pass the validation since miiddleware adds the valid tenant name before actual API call
  const postDataSub = {
    aureus: {
      request: {
        component: 'BillingPayment',
        tenant: 'dev_hs_1',
        application: 'DONNA',
      },
      data: {},
    },
  };
  const strContent = qs.stringify({
    strContent: JSON.stringify(postDataSub),
  });
  const headers = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const { status, data } = await axios.post(path, strContent, headers);
    const { error, data: subscriptionDetails } = data;
    if (status !== 200 || error) {
      throw new Error('Error getting tenant subscription details');
    }
    const { plan } = subscriptionDetails;
    const { planType } = plan;
    const quoteAllowed = isQuoteAllowed(planType);
    response = {
      error: false,
      data: {
        quoteAllowed,
      },
    };
  } catch (error) {
    response = {
      error: true,
      errorMessage: error.message,
    };
  }
  return response;
};

export default isQuoteServiceAllowed;
