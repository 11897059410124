import React, { useContext } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { DashboardSkeleton } from './DashboardSkeleton';
import { DashboardError } from './DashboardError';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { OneView } from './../OneView';
import { DashboardInfobar } from './DashboardInfobar';
import { makeStyles } from '@material-ui/core/styles';
import { MiniWidget } from './MiniWidget';
import { TeamAnalytics } from '../Analytics/TeamAnalytics';
import { dashboardRedirectionMapping } from '../../Enums/dashboardWidgetMapping';
import { UserContext } from '../UserContext';

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '500px',
  vizWidth: '100%',
  vizHeight: '100vh',
};

const basePath = '/api/metrics/v1';

const type = 'book-of-business';
const dashboardApiPath = `${basePath}/${type}`;
const dataEnrichmentMiniWidget = `${basePath}/enrichment/mini`;
const forecastMiniWidget = `${basePath}/foresight/mini`;
const benchmarkMiniWidget = `${basePath}/benchmark/mini`;

const FORESIGHT_PATH = '/home/foresight';
const ENRICHMENT_PATH = '/home/enrichment';
const PORTFOLIO_PATH = '/home/portfolio';
const ANALYTICS_PATH = '/home/analytics';
const SCOPED_ANALYTICS_PATH = '/home/team-analytics';
const PERSONAL_ANALYTICS_PATH = '/home/my-analytics';
const ANALYTICS_CUSTOMER = '/customer/all';
const ANALYTICS_TEAM = '/team';
const ANALYTICS_TRENDS = '/trends';
const ANALYTICS_CUSTOMER_PATH = `${ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const ANALYTICS_TEAM_PATH = `${ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const ANALYTICS_TRENDS_PATH = `${ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const SCOPED_ANALYTICS_CUSTOMER_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const SCOPED_ANALYTICS_TEAM_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const SCOPED_ANALYTICS_TRENDS_PATH = `${SCOPED_ANALYTICS_PATH}${ANALYTICS_TRENDS}`;
const PERSONAL_ANALYTICS_CUSTOMER_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_CUSTOMER}`;
const PERSONAL_ANALYTICS_TEAM_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_TEAM}`;
const PERSONAL_ANALYTICS_TRENDS_PATH = `${PERSONAL_ANALYTICS_PATH}${ANALYTICS_TRENDS}`;

const useStyles = makeStyles((theme) => ({
  miniWidgetContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    maxWidth: 1240,
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'space-between',
  },
  miniWidget: {
    flexShrink: 0,
  },
}));

export function BookOfBusiness() {
  const { user } = useContext(UserContext);
  const match = useRouteMatch();
  const classes = useStyles();
  const history = useHistory();
  const dashboardDetails = useViewDetails(dashboardApiPath);
  const { fetching, success, error, data } = dashboardDetails;

  let portfolioLink = user.getPortfolioPath();
  let customerAnalyticsLink = user.getCustomerAnalyticsPath();
  let teamAnalyticsLink = user.getTeamAnalyticsPath();
  let trendsAnalyticsLink = user.getTrendsAnalyticsPath();

  const mappingObject = {
    customerAnalyticsLink: customerAnalyticsLink,
    teamAnalyticsLink: teamAnalyticsLink,
    trendsAnalyticsLink: trendsAnalyticsLink,
    portfolioLink: portfolioLink,
  };

  const onSelect = async (marks, worksheetName, viz) => {
    const redirectTo = dashboardRedirectionMapping[worksheetName];
    if (redirectTo in mappingObject) {
      history.push(mappingObject[redirectTo]);
    }
  };

  return (
    <>
      <Route path={`${match.url}/oneview/:customerId`}>
        <OneView backPath={match.url} />
      </Route>
      <Route path={`${teamAnalyticsLink}`}>
        <TeamAnalytics type={'analytics'} subType="team" />
      </Route>
      <DashboardInfobar mappingObject={mappingObject} />
      {fetching && <DashboardSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
          onSelect={onSelect}
        />
      )}
      {error && <DashboardError />}
      {
        <div className={classes.miniWidgetContainer}>
          <div className={classes.miniWidget}>
            <MiniWidget
              detailsPath={dataEnrichmentMiniWidget}
              link={ENRICHMENT_PATH}
              linkLabel={'Expand'}
            />
          </div>
          <div className={classes.miniWidget}>
            <MiniWidget
              detailsPath={forecastMiniWidget}
              link={FORESIGHT_PATH}
              linkLabel={'Expand'}
            />
          </div>
          <div className={classes.miniWidget}>
            <MiniWidget
              detailsPath={benchmarkMiniWidget}
              link={trendsAnalyticsLink}
              linkLabel={'Expand'}
            />
          </div>
        </div>
      }
    </>
  );
}
