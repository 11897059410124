import axios from 'axios';

const path = '/api/agencyZoom/v1/getLeadCreationDetails';

const headers = {
  'Content-Type': 'application/json',
};

const getLeadCreationDetails = async (params) => {
  try {
    const { status, data } = await axios.post(path, null, {
      headers,
    });
    if (status !== 200) {
      if (status === 401) {
        return {
          error: true,
          errorMessage: 'Authentication Failure',
          status,
        };
      }
      throw new Error('Error getting lead creation details');
    }
    const { error, errorMessage } = data;
    if (error) {
      throw new Error(errorMessage);
    }
    return data;
  } catch (error) {
    return {
      error: true,
      errorMessage: error.message,
    };
  }
};

export default getLeadCreationDetails;
