import { isCovQuotaForPlan } from './plans';

export class Tenant {
  constructor({ tenantDetails, subscription }) {
    let details;
    let subscriptionDetails;
    if (typeof tenantDetails !== 'object') {
      details = {};
    } else {
      details = { ...tenantDetails };
    }
    if (typeof subscription !== 'object') {
      subscriptionDetails = {};
    } else {
      subscriptionDetails = { ...subscription };
    }
    this.tenantDetails = details;
    this.subscriptionDetails = subscriptionDetails;
    this.getContextIdentifier = this.getContextIdentifier.bind(this);
    this.getAgencyName = this.getAgencyName.bind(this);
    this.getAgencyLogo = this.getAgencyLogo.bind(this);
    this.getAmsName = this.getAmsName.bind(this);
    this.getPlanType = this.getPlanType.bind(this);
  }

  getContextIdentifier() {
    const { covContextIdentifier } = this.tenantDetails;
    return covContextIdentifier;
  }

  getAgencyName() {
    const { agencyName } = this.tenantDetails;
    return agencyName;
  }

  getAgencyLogo() {
    const { agencyLogo } = this.tenantDetails;
    return agencyLogo;
  }

  getAmsName() {
    const { amsName } = this.tenantDetails;
    if (amsName !== null && amsName !== undefined && amsName !== '') {
      return amsName.toString().toLowerCase();
    }
    return '';
  }

  getPlanType() {
    try {
      const { plan } = this.subscriptionDetails ? this.subscriptionDetails : {};
      const { planType } = plan ? plan : {};
      return planType;
    } catch (e) {
      return undefined;
    }
  }

  hasHanoverAI() {
    try {
      const { plan } = this.subscriptionDetails ? this.subscriptionDetails : {};
      const { feature_list } = plan ? plan : {};
      const { features } = feature_list ? feature_list : {};
      let hanoverAI = false;
      if (features && features.includes('Hanover AI')) {
        hanoverAI = true;
      }
      return hanoverAI;
    } catch (e) {
      return undefined;
    }
  }

  hasCovQuota() {
    const planType = this.getPlanType();
    if (planType) {
      return isCovQuotaForPlan(planType);
    }
    return false;
  }
}
