import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import { yellow, green } from '@material-ui/core/colors';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import { ConfigurationIcon, LockImageIcon } from '../Header/Icons';
import { useGetQuoteEnabled } from './useGetQuoteEnabled';
import { useSetQuoteEnabled } from './useSetQuoteEnabled';
import tarmika from '../../assets/Tarmika.svg';
import agencyZoom from '../../assets/AgencyZoom.svg';
import insuredMine from '../../assets/InsuredMine.svg';
import { useSetAgencyZoomEnabled } from './useSetAgencyZoomEnabled';
import { useGetAgencyZoomEnabled } from './useGetAgencyZoomEnabled';
import { useGetInsuredMineEnabled } from './useGetInsuredMineEnabled';
import { useSetInsuredMineEnabled } from './useSetInsuredMineEnabled';
import { useTenantDetails } from '../Header/useTenantDetails';
import {
  isAgencyZoomAllowed,
  isQuoteAllowed,
} from '../../utils/donnaPlansUtil';
import { useGetSessionDetails } from '../UserContextProvider/useGetSessionDetails';
import { User } from '../UserContextProvider/User';
import useGetActivationDetails from './useGetActivationDetails';

const useStyles = makeStyles((theme) => ({
  head: {
    lineHeight: '14px',
    padding: '16px 30px 20px 20px',
    fontSize: 12,
    fontWeight: '600',
    color: '#8DA9BF',
  },
  body: {
    height: 31,
    padding: '15px 20px',
  },
  partnerImg: {
    width: 128,
    objectFit: 'contain',
  },
  statusMainDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  statusdiv: {
    width: 15,
    height: 15,
    left: 192,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
    marginRight: 5,
  },
  activeStatus: {
    background: green[500],
  },
  inactiveStatus: {
    background: yellow[500],
  },
  bodyText: {
    fontSize: 12,
    color: '#4A4A4A',
    fontWeight: 500,
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  configureButton: {
    color: '#4C94EC',
    textTransform: 'capitalize',
    boxShadow: 'none',
    fontSize: 14,
    backgroundColor: '#e1f0ff',
    lineHeight: '17px',
    padding: '7px 10px',
  },
  blurredElement: {
    filter: 'blur(5px)',
  },
  tableContainer: {
    boxShadow: 'unset',
  },
  capitalizeText: {
    textTransform: 'capitalize',
  },
  titleRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px',
    '& h2': {
      height: 50,
    },
  },
  contentRoot: {
    minHeight: 100,
  },
  enableSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  switchButton: {
    marginLeft: 'auto',
  },
  lockDiv: {
    backgroundColor: 'rgb(45,96,137, 0.2)',
    borderRadius: '50%',
    userSelect: 'none',
  },
  textOverlay: {
    position: 'absolute',
    top: 2,
    filter: 'none',
  },
}));

const rows = ['tarmika', 'agencyZoom', 'insuredMine'];

const IntegrationTableRow = (props) => {
  const classes = useStyles();
  const { row } = props;

  const profile = useGetSessionDetails();
  const user = new User(profile);
  let isSetupUser;

  const [openConfiguration, setOpenConfiguration] = useState(false);
  const [tarmikaPayload, setTarmikaPayload] = useState({});
  const [saveTarmikaEnableState, setSaveTarmikaEnableState] = useState(false);
  const [getTarmikaEnableState, setGetTarmikaEnableState] = useState(true);
  useSetQuoteEnabled(
    saveTarmikaEnableState,
    tarmikaPayload,
    setGetTarmikaEnableState
  );
  const quoteEnabled = useGetQuoteEnabled(getTarmikaEnableState);
  const { fetching, success, data } = quoteEnabled;
  const [tarmikaStatus, setTarmikaStatus] = useState(false);
  const [tarmikaEnableChecked, setTarmikaEnableChecked] = useState(false);

  const [agencyZoomPayload, setAgencyZoomPayload] = useState({});
  const [saveAgencyZoomEnableState, setSaveAgencyZoomEnableState] =
    useState(false);
  const [getAgencyZoomEnableState, setGetAgencyZoomEnableState] =
    useState(true);
  useSetAgencyZoomEnabled(
    saveAgencyZoomEnableState,
    agencyZoomPayload,
    setGetAgencyZoomEnableState
  );
  const agencyZoomEnabled = useGetAgencyZoomEnabled(getAgencyZoomEnableState);
  const {
    fetching: agencyZoomFetching,
    success: agencyZoomSuccess,
    data: agencyZoomData,
  } = agencyZoomEnabled;
  const [agencyZoomStatus, setAgencyZoomStatus] = useState(false);
  const [agencyZoomEnableChecked, setAgencyZoomEnableChecked] = useState(false);

  const [insuredMinePayload, setInsuredMinePayload] = useState({});
  const [saveInsuredMineEnableState, setSaveInsuredMineEnableState] =
    useState(false);
  const [getInsuredMineEnableState, setGetInsuredMineEnableState] =
    useState(true);
  useSetInsuredMineEnabled(
    saveInsuredMineEnableState,
    insuredMinePayload,
    setGetInsuredMineEnableState
  );
  const insuredMineEnabled = useGetInsuredMineEnabled(
    getInsuredMineEnableState
  );
  const {
    fetching: insuredMineFetching,
    success: insuredMineSuccess,
    data: insuredMineData,
  } = insuredMineEnabled;
  const [insuredMineStatus, setInsuredMineStatus] = useState(false);
  const [insuredMineEnableChecked, setInsuredMineEnableChecked] =
    useState(false);

  const handleTarmikaChecked = () => {
    const payloadObj = {
      quoteEnabled: tarmikaEnableChecked,
    };
    setTarmikaPayload(payloadObj);
    setOpenConfiguration(false);
  };

  const handleTarmikaClose = () => {
    setOpenConfiguration(false);
    setTarmikaEnableChecked(data && data.quoteEnabled && data.quoteEnabled);
  };

  const handleAgencyZoomChecked = () => {
    const payloadObj = {
      agencyZoomEnabled: agencyZoomEnableChecked,
    };
    setAgencyZoomPayload(payloadObj);
    setOpenConfiguration(false);
  };

  const handleAgencyZoomClose = () => {
    setOpenConfiguration(false);
    setAgencyZoomEnableChecked(
      agencyZoomData &&
        agencyZoomData.agencyZoomEnabled &&
        agencyZoomData.agencyZoomEnabled
    );
  };

  const handleInsuredMineChecked = () => {
    const payloadObj = {
      insuredMineEnabled: insuredMineEnableChecked,
    };
    setInsuredMinePayload(payloadObj);
    setOpenConfiguration(false);
  };

  const handleInsuredMineClose = () => {
    setOpenConfiguration(false);
    setInsuredMineEnableChecked(
      insuredMineData &&
        insuredMineData.insuredMineEnabled &&
        insuredMineData.insuredMineEnabled
    );
  };

  useEffect(() => {
    setSaveTarmikaEnableState(true);
  }, [tarmikaPayload]);
  useEffect(() => {
    setTarmikaEnableChecked(data && data.quoteEnabled && data.quoteEnabled);
    setTarmikaStatus(data && data.quoteEnabled && data.quoteEnabled);
    setGetTarmikaEnableState(false);
  }, [data]);

  useEffect(() => {
    setSaveAgencyZoomEnableState(true);
  }, [agencyZoomPayload]);
  useEffect(() => {
    setAgencyZoomEnableChecked(
      agencyZoomData &&
        agencyZoomData.agencyZoomEnabled &&
        agencyZoomData.agencyZoomEnabled
    );
    setAgencyZoomStatus(
      agencyZoomData &&
        agencyZoomData.agencyZoomEnabled &&
        agencyZoomData.agencyZoomEnabled
    );
    setGetAgencyZoomEnableState(false);
  }, [agencyZoomData]);

  useEffect(() => {
    setSaveInsuredMineEnableState(true);
  }, [insuredMinePayload]);
  useEffect(() => {
    setInsuredMineEnableChecked(
      insuredMineData &&
        insuredMineData.insuredMineEnabled &&
        insuredMineData.insuredMineEnabled
    );
    setInsuredMineStatus(
      insuredMineData &&
        insuredMineData.insuredMineEnabled &&
        insuredMineData.insuredMineEnabled
    );
    setGetInsuredMineEnableState(false);
  }, [insuredMineData]);

  const { data: tenantDetails } = useTenantDetails();

  if (user.success) {
    isSetupUser = user.isSetupUser();
  }

  const { data: activationData } = useGetActivationDetails(isSetupUser);

  let agencyZoomAllowed;
  let tarmikaAllowed;

  try {
    if (isSetupUser && activationData) {
      const { agencySetupData } = activationData;
      const { plan_type: planType } = agencySetupData ? agencySetupData : {};
      if (planType === undefined) {
        throw new Error();
      }
      agencyZoomAllowed = isAgencyZoomAllowed(planType);
      tarmikaAllowed = isQuoteAllowed(planType);
    } else if (!isSetupUser && tenantDetails) {
      const { billingStatus } = tenantDetails;
      if (billingStatus === undefined) {
        throw new Error();
      }
      const billingStatusJSON = JSON.parse(tenantDetails.billingStatus);
      const { plan_type: planType } = billingStatusJSON;
      if (planType === undefined) {
        throw new Error();
      }
      agencyZoomAllowed = isAgencyZoomAllowed(planType);
      tarmikaAllowed = isQuoteAllowed(planType);
    }
  } catch (e) {
    agencyZoomAllowed = false;
    tarmikaAllowed = false;
  }

  const displayInsuredMine =
    tenantDetails && tenantDetails.displayInsuredMineRow;

  if (row === 'tarmika' && tarmikaAllowed !== undefined) {
    return (
      <TableRow
        key={row.name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        className={{ [classes.lockDiv]: !tarmikaAllowed }}
      >
        <TableCell classes={{ body: classes.body }} style={{ width: 142 }}>
          <img src={tarmika} alt="img" className={classes.partnerImg} />
        </TableCell>
        <TableCell classes={{ body: classes.body }} style={{ width: 174 }}>
          {tarmikaAllowed && fetching && (
            <Skeleton variant="rectangular" width={170} height={17} />
          )}
          {tarmikaAllowed && success && (
            <div className={classes.statusMainDiv}>
              <div
                className={clsx(classes.statusdiv, {
                  [classes.activeStatus]: tarmikaStatus,
                  [classes.inactiveStatus]: !tarmikaStatus,
                })}
              ></div>
              <Typography
                className={clsx(classes.bodyText, classes.capitalizeText)}
              >
                {tarmikaStatus && 'active'}
                {!tarmikaStatus && 'inactive'}
              </Typography>
            </div>
          )}
        </TableCell>
        <TableCell classes={{ body: classes.body }} style={{ width: 787 }}>
          {tarmikaAllowed && (
            <Typography className={classes.bodyText}>
              A single-entry solution designed to streamline the quoting process
            </Typography>
          )}
          {!tarmikaAllowed && (
            <div style={{ display: 'flex', gap: 10 }}>
              <LockImageIcon />
              <Typography
                className={classes.bodyText}
                style={{ marginLeft: 10, alignSelf: 'center' }}
              >
                Upgrade to higher plan to avail this feature
              </Typography>
            </div>
          )}
        </TableCell>
        <TableCell classes={{ body: classes.body }} align="center">
          <Button
            variant="contained"
            className={clsx(classes.configureButton, {
              [classes.blurredElement]: !tarmikaAllowed,
            })}
            disabled={!tarmikaAllowed}
            classes={{
              startIcon: classes.startIcon,
            }}
            startIcon={<ConfigurationIcon />}
            onClick={() => {
              setOpenConfiguration(true);
            }}
          >
            Configure
          </Button>
        </TableCell>
        <Dialog
          open={openConfiguration}
          onClose={() => setOpenConfiguration(false)}
          maxWidth="xs"
          fullWidth={true}
        >
          <DialogTitle classes={{ root: classes.titleRoot }}>
            <img src={tarmika} alt="img" height={50} />
          </DialogTitle>
          <Divider />
          <DialogContent classes={{ root: classes.contentRoot }}>
            <div className={classes.enableSwitch}>
              <Typography>Enabled</Typography>
              {success && (
                <Switch
                  checked={tarmikaEnableChecked}
                  onChange={() => {
                    if (!tarmikaEnableChecked) {
                      setTarmikaEnableChecked(true);
                    } else {
                      setTarmikaEnableChecked(false);
                    }
                  }}
                  classes={{
                    root: classes.switchButton,
                  }}
                />
              )}
            </div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button autoFocus onClick={() => handleTarmikaClose()}>
              Close
            </Button>
            <Button autoFocus onClick={() => handleTarmikaChecked()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </TableRow>
    );
  } else if (row === 'agencyZoom' && agencyZoomAllowed !== undefined) {
    return (
      <TableRow
        key={row.name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        className={{ [classes.lockDiv]: !agencyZoomAllowed }}
      >
        <TableCell
          classes={{
            body: classes.body,
          }}
          style={{ width: 142 }}
        >
          <img src={agencyZoom} alt="img" className={classes.partnerImg} />
        </TableCell>
        <TableCell classes={{ body: classes.body }} style={{ width: 174 }}>
          {agencyZoomAllowed && agencyZoomFetching && (
            <Skeleton variant="rectangular" width={170} height={17} />
          )}
          {agencyZoomAllowed && agencyZoomSuccess && (
            <div className={classes.statusMainDiv}>
              <div
                className={clsx(classes.statusdiv, {
                  [classes.activeStatus]: agencyZoomStatus,
                  [classes.inactiveStatus]: !agencyZoomStatus,
                })}
              ></div>
              <Typography
                className={clsx(classes.bodyText, classes.capitalizeText)}
              >
                {agencyZoomStatus && 'active'}
                {!agencyZoomStatus && 'inactive'}
              </Typography>
            </div>
          )}
        </TableCell>
        <TableCell classes={{ body: classes.body }} style={{ width: 787 }}>
          {agencyZoomAllowed && (
            <Typography className={classes.bodyText}>
              Increase sales, boost retention and analyze agency & producer
              performance
            </Typography>
          )}
          {!agencyZoomAllowed && (
            <div style={{ display: 'flex', gap: 10 }}>
              <LockImageIcon />
              <Typography
                className={classes.bodyText}
                style={{ marginLeft: 10, alignSelf: 'center' }}
              >
                Upgrade to higher plan to avail this feature
              </Typography>
            </div>
          )}
        </TableCell>
        <TableCell classes={{ body: classes.body }} align="center">
          <Button
            variant="contained"
            className={clsx(classes.configureButton, {
              [classes.blurredElement]: !agencyZoomAllowed,
            })}
            classes={{
              startIcon: classes.startIcon,
            }}
            disabled={!agencyZoomAllowed}
            startIcon={<ConfigurationIcon />}
            onClick={() => {
              setOpenConfiguration(true);
            }}
          >
            Configure
          </Button>
        </TableCell>
        <Dialog
          open={openConfiguration}
          onClose={() => setOpenConfiguration(false)}
          maxWidth="xs"
          fullWidth={true}
        >
          <DialogTitle classes={{ root: classes.titleRoot }}>
            <img src={agencyZoom} alt="img" height={50} />
          </DialogTitle>
          <Divider />
          <DialogContent classes={{ root: classes.contentRoot }}>
            <div className={classes.enableSwitch}>
              <Typography>Enabled</Typography>
              {agencyZoomSuccess && (
                <Switch
                  checked={agencyZoomEnableChecked}
                  onChange={() => {
                    if (!agencyZoomEnableChecked) {
                      setAgencyZoomEnableChecked(true);
                    } else {
                      setAgencyZoomEnableChecked(false);
                    }
                  }}
                  classes={{
                    root: classes.switchButton,
                  }}
                />
              )}
            </div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button autoFocus onClick={() => handleAgencyZoomClose()}>
              Close
            </Button>
            <Button autoFocus onClick={() => handleAgencyZoomChecked()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </TableRow>
    );
  } else if (displayInsuredMine) {
    return (
      <>
        <TableRow
          key={row.name}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell classes={{ body: classes.body }} style={{ width: 142 }}>
            <img src={insuredMine} alt="img" className={classes.partnerImg} />
          </TableCell>
          <TableCell classes={{ body: classes.body }} style={{ width: 174 }}>
            {insuredMineFetching && (
              <Skeleton variant="rectangular" width={170} height={17} />
            )}
            {insuredMineSuccess && (
              <div className={classes.statusMainDiv}>
                <div
                  className={clsx(classes.statusdiv, {
                    [classes.activeStatus]: insuredMineStatus,
                    [classes.inactiveStatus]: !insuredMineStatus,
                  })}
                ></div>
                <Typography
                  className={clsx(classes.bodyText, classes.capitalizeText)}
                >
                  {insuredMineStatus && 'active'}
                  {!insuredMineStatus && 'inactive'}
                </Typography>
              </div>
            )}
          </TableCell>
          <TableCell classes={{ body: classes.body }} style={{ width: 787 }}>
            <Typography className={classes.bodyText}>
              Everything Sales, Marketing, and Analytics, in One Platform to
              Help Manage The Process from Lead to Renewal
            </Typography>
          </TableCell>
          <TableCell classes={{ body: classes.body }} align="center">
            <Button
              variant="contained"
              className={classes.configureButton}
              classes={{
                startIcon: classes.startIcon,
              }}
              startIcon={<ConfigurationIcon />}
              onClick={() => {
                setOpenConfiguration(true);
              }}
            >
              Configure
            </Button>
          </TableCell>
          <Dialog
            open={openConfiguration}
            onClose={() => setOpenConfiguration(false)}
            maxWidth="xs"
            fullWidth={true}
          >
            <DialogTitle classes={{ root: classes.titleRoot }}>
              <img src={insuredMine} alt="img" height={50} />
            </DialogTitle>
            <Divider />
            <DialogContent classes={{ root: classes.contentRoot }}>
              <div className={classes.enableSwitch}>
                <Typography>Enabled</Typography>
                {insuredMineSuccess && (
                  <Switch
                    checked={insuredMineEnableChecked}
                    onChange={() => {
                      if (!insuredMineEnableChecked) {
                        setInsuredMineEnableChecked(true);
                      } else {
                        setInsuredMineEnableChecked(false);
                      }
                    }}
                    classes={{
                      root: classes.switchButton,
                    }}
                  />
                )}
              </div>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button autoFocus onClick={() => handleInsuredMineClose()}>
                Close
              </Button>
              <Button autoFocus onClick={() => handleInsuredMineChecked()}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </TableRow>
      </>
    );
  } else {
    return null;
  }
};

export default function IntegrationTable(props) {
  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      classes={{ root: classes.tableContainer }}
    >
      <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell classes={{ head: classes.head }}>Name</TableCell>
            <TableCell classes={{ head: classes.head }}>Status</TableCell>
            <TableCell classes={{ head: classes.head }}>Description</TableCell>
            <TableCell classes={{ head: classes.head }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return <IntegrationTableRow row={row} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
