import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useUserList } from './../userUserList';
import Skeleton from '@material-ui/lab/Skeleton';
import { gaEvent } from '../GoogleAnalytics/gaEvent';
import { LocationImageIcon } from '../Header/Icons';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    minWidth: 120,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  select: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#fff',
  },
}));

const LocationSelector = (props) => {
  const classes = useStyles();
  const { value, setValue } = props;
  const handleChange = (event) => {
    const location = locations.find(
      ({ locationID }) => locationID === event.target.value
    );
    if (typeof location === 'object') {
      setValue(location);
      gaTracking(location.name);
    } else {
      setValue({ locationID: -1 });
      gaTracking('All');
    }
  };

  const gaTracking = (location) => {
    const category = 'OfficeReview';
    const action = 'From Location Selector';
    const label = location;
    gaEvent(category, action, label);
  };

  const [toRefresh, clearToRefresh] = useState(true);
  const { fetching, success, data } = useUserList(toRefresh, clearToRefresh);
  let locations;
  let locationMenuItems;
  if (success) {
    ({ locations } = data);
    locationMenuItems = locations.map(({ locationID, name }, index) => (
      <MenuItem key={index} value={locationID}>
        {name}
      </MenuItem>
    ));
    locationMenuItems.unshift(
      <MenuItem key={-1} value={-1}>
        All
      </MenuItem>
    );
  }
  return (
    <>
      {fetching && <Skeleton variant="rect" width="150" height="20" />}
      {success && (
        <>
          <LocationImageIcon />
          <Typography variant="subtitle1" className={classes.label}>
            Select Office Location
          </Typography>
          <FormControl
            className={classes.formControl}
            variant="outlined"
            color="primary"
          >
            <Select
              labelId="view-selector-label"
              id="view-selector"
              value={value}
              onChange={handleChange}
              classes={{
                root: classes.select,
              }}
              color="primary"
              IconComponent={ExpandMoreIcon}
            >
              {locationMenuItems}
            </Select>
          </FormControl>
        </>
      )}
    </>
  );
};

LocationSelector.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

export { LocationSelector };
