import { useState, useEffect } from 'react';

const path = '/api/user/sessionDetails';

function useGetSessionDetails() {
  const [sessionDetails, setSessionDetails] = useState({
    fetching: false,
    error: false,
    success: false,
    data: undefined,
    redirectTo: undefined,
  });
  useEffect(() => {
    const getSessionDetails = async () => {
      setSessionDetails({ fetching: true, error: false, success: false });
      try {
        const response = await fetch(path);
        if (response.redirected) {
          window.location.href = response.url;
        }
        const { error, errorMessage, data } = await response.json();
        if (error) {
          throw new Error(errorMessage);
        }
        setSessionDetails({
          fetching: false,
          error: false,
          success: true,
          data,
        });
      } catch (error) {
        setSessionDetails({ fetching: false, error: true, success: false });
      }
    };
    getSessionDetails();
  }, []);
  return sessionDetails;
}

export { useGetSessionDetails };
