import axios from 'axios';
import qs from 'qs';
import { isAgencyZoomAllowed } from './../../utils/donnaPlansUtil';

const path = '/api/stripe/v1/getSubscriptionDetails/';

const isAgencyZoomServiceAllowed = async () => {
  let response = {};
  const postDataSub = {
    aureus: {
      request: {
        component: 'BillingPayment',
        tenant: 'dev_hs_1',
        application: 'DONNA',
      },
      data: {},
    },
  };
  const strContent = qs.stringify({
    strContent: JSON.stringify(postDataSub),
  });
  const headers = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const { status, data } = await axios.post(path, strContent, headers);
    const { error, data: subscriptionDetails } = data;
    if (status !== 200 || error) {
      throw new Error('Error getting tenant subscription details');
    }
    const { plan } = subscriptionDetails;
    const { planType } = plan;
    const agencyZoomAllowed = isAgencyZoomAllowed(planType);
    response = {
      error: false,
      data: {
        agencyZoomAllowed,
      },
    };
  } catch (error) {
    response = {
      error: true,
      errorMessage: error.message,
    };
  }
  return response;
};

export default isAgencyZoomServiceAllowed;
