import React, { useState } from 'react';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { PortfolioIcon } from '../Header/Icons';
import { Title } from '../Header';
import { dataGridScopes, ServerSideGrid } from '@aureus/donna-data-grid';
import { QuickViewModal } from '../QuickView';
import { OneView } from '../OneView';
import { MyListContext } from '../CardView/MyListContext';
import useOverallTrace from '../CardView/useOverallTrace';
import gaTracking from '../GoogleAnalytics/gaTracking';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import { isTableauDownloadAllowedForPlan } from '../UserContextProvider/plans';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { PortfolioSkeleton } from './PortfolioSkeleton';
import defaultContext from '../../Enums/defaultContext';
import managerConfig from '../OneView/ManagerConfig.json';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(4),
  },
}));

const dimensions = {
  vizWidth: '100%',
  vizHeight: '100px',
};

const insertUserViewEndpoint = '/api/view/v1/add';
const getUserViewNamesEndpoint = '/api/view/v1/getNames';
const getUserViewEndpoint = '/api/view/v1/get';
const deleteUserViewEndpoint = '/api/view/v1/delete';
const allowListRemoval = false;

export function Portfolio() {
  const [customers, setCustomers] = useState([]);
  let match = useRouteMatch();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const tenantApi = user.getTenantApi();
  const planType = tenantApi.getPlanType();
  const allowDownload = isTableauDownloadAllowedForPlan(planType);

  const onSelect = (customerId, customersArr) => {
    if (customerId) {
      setCustomers(customersArr);
      history.push(
        `${match.url}/${defaultContext}/quickview/${customerId}/${defaultContext}`
      );
    }
  };
  const classes = useStyles();
  const portfolioPath = `/api/metrics/v1/portfolio/combined`;
  const portfolioDetails = useViewDetails(portfolioPath);
  const { fetching, success, error, data } = portfolioDetails;

  return (
    <div>
      <Route path={`${match.url}/:context/oneview/:customerId/:selectedView?`}>
        <OneView quick={true} />
      </Route>
      <Route path={`${match.url}/:context/quickview/:customerId/:context?`}>
        <QuickViewModal
          backPath={match.url}
          quick={true}
          customers={customers}
          prefixContext={true}
        />
      </Route>
      <Title icon={PortfolioIcon} titleText="My Portfolio" />
      {fetching && <PortfolioSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          adjustHeight={true}
        />
      )}
      <Container maxWidth="lg">
        <div className={classes.topMargin}>
          <ServerSideGrid
            scope={dataGridScopes.MY_PORTFOLIO}
            insertUserViewEndpoint={insertUserViewEndpoint}
            getUserViewNamesEndpoint={getUserViewNamesEndpoint}
            getUserViewEndpoint={getUserViewEndpoint}
            deleteUserViewEndpoint={deleteUserViewEndpoint}
            onSelect={onSelect}
            MyListContext={MyListContext}
            useOverallTrace={useOverallTrace}
            gaTracking={gaTracking}
            tenant={tenant}
            allowDownload={allowDownload}
            allowListRemoval={allowListRemoval}
            managerConfig={managerConfig}
          />
        </div>
      </Container>
    </div>
  );
}
