import React from 'react';
import PropTypes from 'prop-types';
import { useViewDetails } from './../useViewDetails';
import { TableauMarkSelectionView } from './../TableauView';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  hover: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    opacity: 0,
    zIndex: 1,
    '&:hover': {
      opacity: 1,
    },
  },
  buttonContainer: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const dimensions = {
  placeholderWidth: '100%',
  placeholderHeight: '300px',
  vizWidth: '100%',
  vizHeight: '300px',
};

export function MiniWidget(props) {
  const classes = useStyles();
  const { detailsPath, link, linkLabel } = props;
  const infoBarDetails = useViewDetails(detailsPath);
  const { fetching, success, data } = infoBarDetails;
  const disabled = data ? data.disabled : undefined;

  return (
    <div>
      <div className={classes.roundedNavBar}>
        {fetching && (
          <Skeleton variant="rect" height={dimensions.placeholderHeight} />
        )}
        {success && (
          <>
            {!disabled && link && linkLabel && (
              <div className={classes.hover}>
                <div className={classes.buttonContainer}>
                  <Button
                    href={link}
                    color="primary"
                    disableElevation
                    disableRipple
                    size="small"
                  >
                    {linkLabel}
                  </Button>
                </div>
              </div>
            )}
            <TableauMarkSelectionView
              {...data}
              {...dimensions}
              adjustHeight={true}
            />
          </>
        )}
      </div>
    </div>
  );
}

MiniWidget.propTypes = {
  detailsPath: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
};
