import React, { useState, cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import useGetTableauData from './useGetTableauData';
import useAllCustomers from './useAllCustomers';

const dedupeCustomers = (customers) => {
  if (Array.isArray(customers)) {
    const seen = {};
    return customers.filter((customer) => {
      const { Main: customerId } = customer;
      if (seen[customerId] === true) {
        return false;
      } else {
        seen[customerId] = true;
        return true;
      }
    });
  } else {
    return customers;
  }
};

const isCustomerRemoved = (customer, toRemove) => {
  const { Main: custId } = customer;
  return toRemove[custId];
};

const ContextCardDataProvider = (props) => {
  const { context, children, maxCustomers } = props;
  const [customers, setCustomers] = useState();
  const [customerCount, setCustomerCount] = useState(0);
  const [fetchCardData, setFetchCardData] = useState(true);
  const [countUpdate, setCountUpdate] = useState(false);
  const getTableauDataEndpoint = `/api/tableau/v1/data`;

  const startDataFetch = () => setFetchCardData(true);
  const stopDataFetch = () => setFetchCardData(false);

  const { fetching, success, error } = useGetTableauData(
    context,
    getTableauDataEndpoint,
    fetchCardData,
    stopDataFetch,
    setCustomers,
    setCustomerCount
  );
  useEffect(() => {
    if (fetching === true) {
      setCustomers(undefined);
    }
  }, [fetching]);

  const [load, setLoad] = useState(true);

  const reload = () => {
    setLoad(true);
  };

  const stopLoad = () => {
    setLoad(false);
    setCountUpdate(true);
  };

  const {
    fetching: fetchingCustomers,
    success: successCustomers,
    error: errorCustomers,
    data: allCustomersInSegment,
  } = useAllCustomers(context, load, stopLoad);

  const overallFetching =
    fetching ||
    fetchingCustomers ||
    (success && customers === undefined) ||
    (success && allCustomersInSegment === undefined);
  const overallSuccess =
    success &&
    successCustomers &&
    customers !== undefined &&
    allCustomersInSegment !== undefined;
  const overallError = error || errorCustomers;

  useEffect(() => {
    if (countUpdate && success && successCustomers) {
      setCountUpdate(false);
      const totalCustomers = customerCount - allCustomersInSegment.length;
      if (totalCustomers >= 0) {
        setCustomerCount(totalCustomers);
      }
    }
  }, [
    countUpdate,
    success,
    successCustomers,
    customerCount,
    allCustomersInSegment,
  ]);

  let filteredCustomers;
  if (Array.isArray(customers) && Array.isArray(allCustomersInSegment)) {
    const toRemove = {};
    allCustomersInSegment.forEach((customerInSegment) => {
      const { customerId } = customerInSegment;
      toRemove[customerId] = true;
    });
    filteredCustomers = dedupeCustomers(customers).filter((customer) => {
      return !isCustomerRemoved(customer, toRemove);
    });
    if (maxCustomers) {
      filteredCustomers = filteredCustomers.slice(0, maxCustomers);
    }
  }

  return (
    <>
      {cloneElement(children, {
        fetching: overallFetching,
        success: overallSuccess,
        error: overallError,
        customers: filteredCustomers,
        context,
        reload,
        customerCount,
      })}
    </>
  );
};

ContextCardDataProvider.propTypes = {
  type: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  search: PropTypes.string,
  maxCustomers: PropTypes.number,
};

export { ContextCardDataProvider };
