import axios from 'axios';

const path = '/api/user/donnaTenantDetails';

const isQuoteEnabled = async () => {
  let response = {};
  let quoteEnabled = false;
  try {
    const { status, data } = await axios.get(path);
    const { error, data: tenantDetails } = data;
    if (status !== 200 || error) {
      throw new Error('Error getting donna tenant details');
    }
    const { quoteEnabled: quoteEnabledValue } = tenantDetails;

    if (typeof quoteEnabledValue !== 'boolean') {
      quoteEnabled = quoteEnabledValue === 'true' ? true : false;
    } else {
      quoteEnabled = quoteEnabledValue;
    }
    response = {
      error: false,
      data: {
        quoteEnabled,
      },
    };
  } catch (error) {
    response = {
      error: true,
      errorMessage: error.message,
    };
  }
  return response;
};

export default isQuoteEnabled;
