import React, { useState } from 'react';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import { useViewDetails } from './../useViewDetails';
import {
  TableauMarkSelectionView,
  getCustomerIdFromMarks,
} from './../TableauView';
import { EnrichmentSkeleton } from './EnrichmentSkeleton';
import { EnrichmentError } from './EnrichmentError';
import { OneView } from './../OneView';
import { Title } from '../Header/Title';
import { DataEnrichmentIcon } from '../Header/Icons';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import {
  dataGridScopes,
  enrichmentLevels,
  ServerSideGrid,
} from '@aureus/donna-data-grid';
import { MyListContext } from '../CardView/MyListContext';
import useOverallTrace from '../CardView/useOverallTrace';
import gaTracking from '../GoogleAnalytics/gaTracking';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import { isTableauDownloadAllowedForPlan } from '../UserContextProvider/plans';
import { QuickViewModal } from '../QuickView';
import defaultContext from '../../Enums/defaultContext';
import managerConfig from '../OneView/ManagerConfig.json';
import { EnrichmentWidget } from './EnrichmentWidget';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(4),
  },
  miniWidgetContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    maxWidth: 1239,
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  stateAndZipcodeContainer: {
    marginTop: theme.spacing(4),
    display: 'block',
    maxWidth: 1240,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  miniWidget: {
    maxWidth: 400,
  },
  lastWidget: {
    maxWidth: 400,
    marginRight: theme.spacing(2),
  },
}));

const basePath = '/api/metrics/v1';

const customerEnrichmentWidget = `${basePath}/enrichment/customer`;
const policyEnrichmentWidget = `${basePath}/enrichment/policy`;
const lobEnrichmentWidget = `${basePath}/enrichment/lob`;
const policyTypesEnrichmentWidget = `${basePath}/enrichment/policyTypes`;
const stateAndZipCodeEnrichmentWidget = `${basePath}/enrichment/stateAndZipCode`;

const insertUserViewEndpoint = '/api/view/v1/add';
const getUserViewNamesEndpoint = '/api/view/v1/getNames';
const getUserViewEndpoint = '/api/view/v1/get';
const deleteUserViewEndpoint = '/api/view/v1/delete';

export function Enrichment() {
  const [customers, setCustomers] = useState([]);
  const history = useHistory();
  const match = useRouteMatch();

  const classes = useStyles();
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const tenantApi = user.getTenantApi();
  const planType = tenantApi.getPlanType();
  const allowDownload = isTableauDownloadAllowedForPlan(planType);

  const onCustomerSelect = (customerId, customersArr) => {
    if (customerId) {
      setCustomers(customersArr);
      history.push(
        `${match.url}/${defaultContext}/quickview/${customerId}/${defaultContext}`
      );
    }
  };
  return (
    <div>
      <Title
        titleText="Data Enrichment"
        updateButton={true}
        icon={DataEnrichmentIcon}
      />
      <Route
        path={`${match.url}/${defaultContext}/quickview/:customerId/:context?`}
      >
        <QuickViewModal
          backPath={match.url}
          quick={true}
          customers={customers}
          prefixContext={true}
        />
      </Route>
      <Route
        path={`${match.url}/${defaultContext}/oneview/:customerId/:selectedView?`}
      >
        <OneView quick={true} />
      </Route>
      <Route path={`${match.url}/oneview/:customerId`}>
        <OneView backPath={match.url} />
      </Route>
      <>
        {
          <>
            <div className={classes.miniWidgetContainer}>
              <div className={classes.miniWidget}>
                <EnrichmentWidget detailsPath={customerEnrichmentWidget} />
              </div>
              <Divider orientation="vertical" variant="middle" flexItem />
              <div className={classes.miniWidget}>
                <EnrichmentWidget detailsPath={policyEnrichmentWidget} />
              </div>
              <Divider orientation="vertical" variant="middle" flexItem />
              <div className={classes.miniWidget}>
                <EnrichmentWidget detailsPath={lobEnrichmentWidget} />
              </div>
              <Divider orientation="vertical" variant="middle" flexItem />
              <div className={classes.lastWidget}>
                <EnrichmentWidget detailsPath={policyTypesEnrichmentWidget} />
              </div>
            </div>
            <div className={classes.stateAndZipcodeContainer}>
              <EnrichmentWidget detailsPath={stateAndZipCodeEnrichmentWidget} />
            </div>
          </>
        }
        <Container maxWidth="lg">
          <div className={classes.topMargin}>
            <ServerSideGrid
              scope={dataGridScopes.DATA_ENRICHMENT}
              insertUserViewEndpoint={insertUserViewEndpoint}
              getUserViewNamesEndpoint={getUserViewNamesEndpoint}
              getUserViewEndpoint={getUserViewEndpoint}
              deleteUserViewEndpoint={deleteUserViewEndpoint}
              onSelect={onCustomerSelect}
              MyListContext={MyListContext}
              useOverallTrace={useOverallTrace}
              gaTracking={gaTracking}
              tenant={tenant}
              allowDownload={allowDownload}
              dataEnrichmentLevel={enrichmentLevels.LEVEL0}
              managerConfig={managerConfig}
            />
          </div>
        </Container>
      </>
    </div>
  );
}
