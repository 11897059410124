import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { InfobarSkeleton } from './InfobarSkeleton';
import { AnalyticsError } from './AnalyticsError';
import { useViewDetails } from './../useViewDetails';
import {
  TableauMarkSelectionView,
  getCustomerIdFromMarks,
} from './../TableauView';
import { OneView } from './../OneView';
import base64url from 'base64url';
import { Title } from '../Header';
import { CustomersIcon } from '../Header/Icons.js';
import { dataGridScopes, ServerSideGrid } from '@aureus/donna-data-grid';
import { MyListContext } from '../CardView/MyListContext';
import useOverallTrace from '../CardView/useOverallTrace';
import gaTracking from '../GoogleAnalytics/gaTracking';
import { useContext } from 'react';
import { UserContext } from './../UserContext';
import { isTableauDownloadAllowedForPlan } from '../UserContextProvider/plans';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { QuickViewModal } from '../QuickView';
import defaultContext from '../../Enums/defaultContext';
import managerConfig from '../OneView/ManagerConfig.json';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    marginTop: theme.spacing(4),
  },
}));

const dimensions = {
  vizWidth: '100%',
  vizHeight: '100px',
};

const carrierKey = 'Dvcarrier';
const insertUserViewEndpoint = '/api/view/v1/add';
const getUserViewNamesEndpoint = '/api/view/v1/getNames';
const getUserViewEndpoint = '/api/view/v1/get';
const deleteUserViewEndpoint = '/api/view/v1/delete';

export function CustomerAnalytics(props) {
  const { type } = props;
  const [customers, setCustomers] = useState([]);
  const history = useHistory();
  const match = useRouteMatch();
  const { carriers } = useParams();
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const tenant = user.getTenant();
  const tenantApi = user.getTenantApi();
  const planType = tenantApi.getPlanType();
  const allowDownload = isTableauDownloadAllowedForPlan(planType);
  const onSelect = (marks) => {
    const customerId = getCustomerIdFromMarks(marks);
    if (customerId) {
      history.push(`${match.url}/oneview/${customerId}`);
    }
  };
  const analyticsApiPath = `/api/metrics/v1/${type}/customer`;
  const analyticsDetails = useViewDetails(analyticsApiPath);
  const { fetching, success, error, data } = analyticsDetails;
  if (data && carriers) {
    const { filter } = data;
    let modifiedFilter;
    const decodedCarriers = base64url.decode(carriers);
    if (typeof filter === 'string') {
      if (filter === '') {
        modifiedFilter = `${carrierKey}=${decodedCarriers}`;
      } else {
        modifiedFilter = `${filter}&${carrierKey}=${decodedCarriers}`;
      }
    }
    data.filter = modifiedFilter;
  }
  const onCustomerSelect = (customerId, customersArr) => {
    if (customerId) {
      setCustomers(customersArr);
      history.push(
        `${match.url}/${defaultContext}/quickview/${customerId}/${defaultContext}`
      );
    }
  };

  return (
    <div>
      <Title icon={CustomersIcon} titleText="Customers" />
      <Route
        path={`${match.url}/${defaultContext}/quickview/:customerId/:context?`}
      >
        <QuickViewModal
          backPath={match.url}
          quick={true}
          customers={customers}
          prefixContext={true}
        />
      </Route>
      <Route
        path={`${match.url}/${defaultContext}/oneview/:customerId/:selectedView?`}
      >
        <OneView backPath={match.url} />
      </Route>
      {fetching && <InfobarSkeleton />}
      {success && (
        <TableauMarkSelectionView
          {...data}
          {...dimensions}
          onSelect={onSelect}
          adjustHeight={true}
        />
      )}
      <Container maxWidth="lg">
        <div className={classes.topMargin}>
          <ServerSideGrid
            scope={dataGridScopes.CUSTOMER_ANALYTICS}
            insertUserViewEndpoint={insertUserViewEndpoint}
            getUserViewNamesEndpoint={getUserViewNamesEndpoint}
            getUserViewEndpoint={getUserViewEndpoint}
            deleteUserViewEndpoint={deleteUserViewEndpoint}
            onSelect={onCustomerSelect}
            MyListContext={MyListContext}
            useOverallTrace={useOverallTrace}
            gaTracking={gaTracking}
            tenant={tenant}
            allowDownload={allowDownload}
            managerConfig={managerConfig}
          />
        </div>
      </Container>
      {error && <AnalyticsError />}
    </div>
  );
}

CustomerAnalytics.propTypes = {
  type: PropTypes.string.isRequired,
};
