import { getAllMarkValues } from './marks';

const customerCountKeys = [
  'AGG(Calculated Display Insight Display Value)',
  'acg(calculated display insight display value)',
  'AGG(Calculated_Display_Insight_Display_Value)',
  'AGG(CalculatedDisplayInsightDisplayValue)',
  'AGG(calculatedDisplayInsightDisplayValue)',
  'AGG(calculated_display_insight_display_value)',
];

export const getInsightCustomerCountFromMarks = (marks) => {
  return getAllMarkValues(marks, customerCountKeys);
};
